<template>
  <div class="form">
    <div class="wrapper">
      <Backlink title="detail" service="mortgage" backlink="/mortgage/realty" />
      <div class="content">
        <div class="content__container mobile_center">
          <Question
            service="mortgage"
            name="sellbuy"
            question="question_6"
            successUrl="/mortgage/already-sold"
            negativeUrl="/mortgage/banks"
            :droppedSteps="dropped_steps"
            :negativeMissedSteps="dropped_steps"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Backlink from '@/components/base/Backlink.vue'
import Question from '@/components/Question.vue'

export default {
  name: 'Mortgage_sell_and_buy',
  components: { Backlink, Question },
  data: () => ({
    dropped_steps: [
      '/mortgage/already-sold',
      '/mortgage/old-realty-price',
      '/mortgage/old-realty-mortgage',
      '/mortgage/sell-during',
      '/mortgage/help',
      '/mortgage/old-realty-price-not-sold',
      '/mortgage/old-mortgage-not-sold-realty',
      '/mortgage/old-mortgage-not-sold-price',
      '/mortgage/self-money',
    ]
  })
}
</script>
